import { AppModule } from 'app/module';
import 'module/icon/main';
import Template from './template.hbs';

export class IconLabel extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    getPropsFromDom() {
        return {
            iconLabelEuro: !!this.dom.el.querySelector('.m-icon__euro'),
            iconLabelBag: !!this.dom.el.querySelector('.m-icon__bag'),
            iconLabelMagnifier: !!this.dom.el.querySelector('.m-icon__magnifier'),
            iconLabelLocation: !!this.dom.el.querySelector('.m-icon__location'),
            iconLabelEmail: !!this.dom.el.querySelector('.m-icon__email'),
            iconLabelPen: !!this.dom.el.querySelector('.m-icon__edit'),
            iconLabelLink: !!this.dom.el.querySelector('.m-icon__share'),
        };
    }
}
